<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      class="mx-lg-auto d-lg-none"
      src="/img/brand/logo_skl.svg"
      width="97"
      height="46"
      alt="CoreUI Logo"
    />
    <div>
      <h1 class="text-uppercase ml-2 theheader-txt font-weight-bold">
        Admin Site
      </h1>
    </div>
    <CHeaderNav class="d-md-down-none mr-auto">
    </CHeaderNav>
    <CHeaderNav class="mr-1 mr-sm-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <b-dropdown class="m-2 lang-dd border-0" right>
          <template v-slot:button-content>{{ `${profileInfo.firstname} ${profileInfo.lastname}` }}</template>
          <b-dropdown-item-button @click="handleLogout"
            >ออกจากระบบ</b-dropdown-item-button
          >
        </b-dropdown>
      </CHeaderNavItem>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>

    <b-modal
      ref="logOutModal"
      id="logOutModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <h4>ออกจากระบบ</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('logOutModal')"
        >
          ×
        </button>
      </div>
      <div class="text-center px-md-5 py-4">
        <p class="text-text font-weight-bold">
          คุณแน่ใจหรือไม่ว่าคุณต้องการออกจากระบบ?
        </p>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button
          class="mr-2 btn-modal"
          variant="secondary"
          @click="$bvModal.hide('logOutModal')"
          >ไม่</b-button
        >
        <b-button class="ml-2 btn-modal" variant="primary" @click="logout"
          >ใช่</b-button
        >
      </div>
    </b-modal>

    <b-modal
      ref="alertModal"
      id="alertModal"
      hide-header
      hide-footer
      centered
      size="sm"
      no-close-on-backdrop
    >
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 img"
        />
        <p class="text-text font-weight-bold">กรุณารอสักครู่...</p>
      </div>
    </b-modal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import ModalLoading from "@/components/modal/alert/ModalLoading";
// import VueCookies from "vue-cookies";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
      displayName: "",
      name: "",
      modalMessage: "Are you sure you want to log out ?",
      routeMeta : {
        // isChildren: false,
      }
      // languages: ["en", "th"]
    };
  },
  computed: {
    ...mapState({
      profileInfo: (state) => state.profile,
    }),
  },
  created: async function() {
    // this.name = VueCookies.get("username");
    await this.getProfile();
      this.handleActiveMenuChild(this.$route.meta);
  },
  watch: {
    "$route.meta"(newValue, oldValue) {
      this.handleActiveMenu(newValue, oldValue);
    }
  },
  methods: {
    //   changeLang(lang) {
    //     this.$i18n.locale = lang;
    //   },
    handleActiveMenu: async function(newValue, oldValue){
      if(newValue.activeUrl != oldValue.activeUrl){
        await this.handleRemoveActiveMenuChild(oldValue);
      }
      await this.handleActiveMenuChild(newValue);
    },
    handleActiveMenuChild: async function(meta){
      Vue.nextTick(async () => {
        // .ps__rail-y
        var ele = await this.getAllElementsWithAttribute('a', 'href', meta.activeUrl);
        var parentElement = ele.parentElement.parentElement.parentElement;

        if(parentElement && parentElement.className == "c-sidebar-nav-dropdown")
          parentElement.setAttribute("class", "c-sidebar-nav-dropdown c-show");

        if(meta.lv == 1 && ele.className == "c-sidebar-nav-dropdown")
          ele.setAttribute("class", "c-sidebar-nav-dropdown c-show");
        else if(meta.lv == 3 && parentElement.parentElement.parentElement.className == "c-sidebar-nav-dropdown") 
          parentElement.parentElement.parentElement.setAttribute("class", "c-sidebar-nav-dropdown c-show");

        if(meta.isChildren && ele.className == "c-sidebar-nav-link")
          await ele.setAttribute("class", "router-link-exact-active c-active c-sidebar-nav-link");
        
        setTimeout(()=>{ 
          var div = document.getElementsByClassName("ps__rail-y")[0];
          div.style.top = `${ele.getBoundingClientRect().top}px`
          ele.focus();
        }, 500);
        this.routeMeta = meta;
      });
    },
    handleRemoveActiveMenuChild: async function(oldMeta){
      Vue.nextTick(async () => {
        var oldEle = this.getAllElementsWithAttribute('a', 'href', oldMeta.activeUrl);
        var parentElement = oldEle.parentElement.parentElement.parentElement;

        if(parentElement && parentElement.className == "c-sidebar-nav-dropdown c-show")
          parentElement.setAttribute("class", "c-sidebar-nav-dropdown");

        if(oldMeta.lv == 1 && oldEle.className == "c-sidebar-nav-dropdown c-show")
          oldEle.setAttribute("class", "c-sidebar-nav-dropdown");
        else if(oldMeta.lv == 3 && parentElement.parentElement.parentElement.className == "c-sidebar-nav-dropdown c-show") 
          parentElement.parentElement.parentElement.setAttribute("class", "c-sidebar-nav-dropdown");

        if(oldMeta.isChildren && oldEle.className == "router-link-exact-active c-active c-sidebar-nav-link")
          await oldEle.setAttribute("class", "c-sidebar-nav-link");
      });
    },
    getAllElementsWithAttribute(tagName, attribute, val)
    {
      var allElements = Array.from(document.getElementsByTagName(tagName));
      var filtered = allElements.filter((ele) => {
          return ele.getAttribute(attribute) !== null && ele.getAttribute(attribute) == val;
        }
      );
      if(filtered.length)
        return filtered[0];
      else
        return false;
    },
    getProfile: async function() {
      await this.$store.dispatch("getUserApi");
    },
    handleLogout() {
      this.$refs.logOutModal.show();
    },
    logout: async function() {
      this.$refs.logOutModal.hide();
      this.$refs.alertModal.show();

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/logout`,
        null,
        this.$headers,
        null
      );
      this.$cookies.remove("admin-token");
      window.location.href = "/login";
    }
  },
};
</script>

<style scoped>
::v-deep .lang-dd button {
  background-color: transparent;
  border: none;
  padding: 0;
}

::v-deep .lang-dd button:focus,
::v-deep .lang-dd button:active,
::v-deep .lang-dd.show > .btn-secondary.dropdown-toggle,
::v-deep .lang-dd button:hover {
  background-color: transparent !important;
  border: none;
  padding: 0;
}

.lang-dd {
  border: 1px solid #dcdcdc;
  padding: 5px 10px;
}

::v-deep .lang-dd ul {
  width: 100px;
}

.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}

.text-msg {
  font-size: 2rem;
  color: #4a4a4a;
}

.btn-modal {
  width: 100px;
  font-weight: bold;
}

.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.img {
  width: 100px;
}
</style>
