<template>
  <CFooter>
    <div>
      <span class="ml-1">Admin &copy; {{new Date() | moment('YYYY')}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
